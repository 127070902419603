<template>
  <div id="app">
    <router-view style="box-sizing: border-box; height: 100%" />
  </div>
</template>

<script>
import { initUser } from "./http/api";
export default {
  name: "App",

  mounted() {
    console.log("当前环境：", process.env.NODE_ENV);
    console.log("当前环境路径：", process.env.VUE_APP_URL);
    if (this.$route.query.token) {
      // 微信登录
      localStorage.setItem("token", this.$route.query.token);
    }
    if (window.location.hash) {
      // 手机号登录
      localStorage.setItem("token", window.location.hash.substring(1));
    }
    if (localStorage.getItem("token")) {
      initUser();
    }
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  background: #f8f8f8;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
}
.van-tabbar-item--active {
  color: #06c05f !important;
}
</style>
