<template>
  <div class="icon">
    <div class="img">
      <img :src="require('@img/' + url)" alt="">
    </div>
    <div>{{text}}</div>
  </div>
</template>

<script>
export default {
  name: 'common-icon',
  props: {
    url: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    }
  },
}
</script>

<style lang="less" scoped>
.icon {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  .img {
    width: 58px;
    height: 58px;
    margin-bottom: 5px;
    img {
      width: 100%;
    }
  }
}
</style>