import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "./utils/filters";
import "vant/lib/index.less";
import "./styles/common.less";
import {
  PullRefresh,
  Checkbox,
  CheckboxGroup,
  Button,
  Col,
  Row,
  Field,
  Form,
  RadioGroup,
  Radio,
  List,
  Uploader,
  ActionSheet,
  Tabbar,
  TabbarItem,
  Dialog,
  Toast,
  SwipeCell,
  Icon,
  Popup,
  Picker,
  DatetimePicker,
  ImagePreview,
} from "vant";
import VueClipboard from "vue-clipboard2";

Vue.use(PullRefresh);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Form);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(List);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(SwipeCell);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(ImagePreview);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string]: any })[key]);
});


import baseInfo from './baseInfo';
Vue.use(baseInfo);


new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
