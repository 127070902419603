<template>
  <van-tabbar route :before-change="beforeChange">
    <van-tabbar-item replace name="home" to="/home">
      <template #icon="props">
        <img :src="props.active ? icon.home_a : icon.home" />
      </template>
      首页
    </van-tabbar-item>
    <van-tabbar-item replace name="invoiceList" to="/invoice">
      <template #icon="props">
        <img :src="props.active ? icon.file_a : icon.file" />
      </template>
      电子发票
    </van-tabbar-item>
    <van-tabbar-item replace name="billList" to="/bill">
      <template #icon="props">
        <img :src="props.active ? icon.bill_a : icon.bill" />
      </template>
      单据
    </van-tabbar-item>
    <van-tabbar-item replace name="user" to="/user">
      <template #icon="props">
        <img :src="props.active ? icon.me_a : icon.me" />
      </template>
      我的
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "layout-footer",

  data() {
    return {
      icon: {
        home: require("@img/home.png"),
        home_a: require("@img/home-a.png"),
        file: require("@img/file.png"),
        file_a: require("@img/file-a.png"),
        bill: require("@img/bill.png"),
        bill_a: require("@img/bill-a.png"),
        me: require("@img/me.png"),
        me_a: require("@img/me-a.png"),
      },
    };
  },

  methods: {
    beforeChange(name) {
      if (name != "home") {
        if (this.$loginState()) {
          return true
        } else {
          if (this.$isWXMiniProgram()) {
            if (name != "user") {
              this.$wechatMiniProgramToLogin();
              return false;
            }
            else{
              return true;
            }
          } else {
            this.$toast("请先登录");
            return false;
          }
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
/* .van-tabbar.van-tabbar--fixed {
  margin-bottom: 34px;
} */
.van-tabbar-item__text {
  font-size: 10px;
}
.van-tabbar-item__icon img {
  height: 22px;
  display: block;
}
</style>
