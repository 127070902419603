import Vue from "vue";
declare const window: Window & {
  __wxjs_environment: any;
  miniprogramWx: any;
  wx: any;
};
export default {
  install() {
    let loginState = false;

    Vue.prototype.$loginState = function () {
      const token = localStorage.getItem("token");
      if (token) {
        loginState = true;
      } else {
        loginState = false;
      }
      return loginState;
    };

    Vue.prototype.$isWXMiniProgram = function () {
      return window.__wxjs_environment === "miniprogram";
    };

    Vue.prototype.$checkLoginState = function (fun: () => void) {
      const self = this;
      if (self.$loginState()) {
        fun();
      } else {
        if (self.$isWXMiniProgram()) {
          self.$wechatMiniProgramToLogin();
          return false;
        } else {
          this.$toast("请先登录");
          return false;
        }
      }
    };

    Vue.prototype.$wechatMiniProgramToLogin = function (redirectUrl: string) {
      Vue.prototype.$wechatMiniProgramNavigateTo({
        url:
          "/pages/login/index?redirectUrl=" +
          encodeURIComponent(redirectUrl || ""),
      });
    };
    Vue.prototype.$wechatMiniProgramNavigateTo = function (params: {
      url: string;
    }) {
      window.wx.miniProgram.navigateTo(params);
    };
    Vue.prototype.$hrefTo = function (url: string) {
      if (this.$isWXMiniProgram()) {
        window.wx.miniProgram.redirectTo({
          url: url,
        });
      } else {
        this.$toast("不是微信小程序");
      }
    };
  },
};
