import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "收票宝",
    },
  },
  {
    path: "/email",
    name: "email",
    component: () =>
      import(/* webpackChunkName: "email" */ "../views/home/email/Email.vue"),
    meta: {
      title: "邮件收取记录",
    },
  },
  {
    path: "/email/list",
    name: "email-list",
    component: () =>
      import(
        /* webpackChunkName: "list" */ "../views/home/email/EmailList.vue"
      ),
    meta: {
      title: "邮箱绑定",
    },
  },
  {
    path: "/email/bind",
    name: "email-bind",
    component: () =>
      import(
        /* webpackChunkName: "bind" */ "../views/home/email/EmailBind.vue"
      ),
    meta: {
      title: "邮箱绑定",
    },
  },
  {
    path: "/message",
    name: "message",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/home/Message.vue"),
    meta: {
      title: "短信收取",
    },
  },
  {
    path: "/finance",
    name: "finance",
    component: () =>
      import(
        /* webpackChunkName: "finance" */ "../views/home/finance/Finance.vue"
      ),
    meta: {
      title: "提交财务",
    },
  },
  {
    path: "/finance/submit",
    name: "submitFinance",
    component: () =>
      import(
        /* webpackChunkName: "submitFinance" */ "../views/home/finance/SubmitFinance.vue"
      ),
    meta: {
      title: "提交财务",
    },
  },
  {
    path: "/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "share" */ "../views/home/Share.vue"),
    meta: {
      title: "分享",
    },
  },
  {
    path: "/export",
    name: "export",
    component: () =>
      import(/* webpackChunkName: "export" */ "../views/home/Export.vue"),
    meta: {
      title: "导出",
    },
  },
  {
    path: "/invoice",
    name: "invoiceList",
    component: () =>
      import(
        /* webpackChunkName: "invoiceList" */ "../views/invoice/InvoiceList.vue"
      ),
    meta: {
      title: "电子发票",
    },
  },
  {
    path: "/invoice/detail",
    name: "invoiceDetail",
    component: () =>
      import(
        /* webpackChunkName: "invoiceDetail" */ "../views/invoice/InvoiceDetail.vue"
      ),
    meta: {
      title: "编辑",
    },
  },
  {
    path: "/bill",
    name: "billList",
    component: () =>
      import(/* webpackChunkName: "billList" */ "../views/bill/BillList.vue"),
    meta: {
      title: "单据",
    },
  },
  {
    path: "/bill/detail",
    name: "billDetail",
    component: () =>
      import(
        /* webpackChunkName: "billDetail" */ "../views/bill/BillDetail.vue"
      ),
    meta: {
      title: "编辑",
    },
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/User.vue"),
    meta: {
      title: "我的",
    },
  },
  {
    path: "/company",
    name: "company",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "../views/user/company/CompanyList.vue"
      ),
    meta: {
      title: "我的公司",
    },
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () =>
      import(/* webpackChunkName: "feedback" */ "../views/user/Feedback.vue"),
    meta: {
      title: "意见反馈",
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched[0].meta.title) {
    document.title = to.matched[0].meta.title;
  } else {
    document.title = "收票宝";
  }
  next();
});

export default router;
