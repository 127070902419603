import http from "./axios";

/**
 * 查询用户信息
 */
export const getUserInfo = () => {
  return http.get(`/v2/api/UserInfo`);
};

/**
 * 获取用户注册时间
 */
export const getRegisterTime = () => {
  return http.get("/spb/user");
};

/**
 * 初始化用户信息
 */
export const initUser = () => {
  return http.post("/spb/user");
};

/**
 * 邮箱收取进度查询
 */
export const getEmailProgress = (params: any) => {
  return http.get("/spb/email", { params });
};

/**
 * 搜索所有的邮箱
 */
export const getEmails = () => {
  return http.get("/spb/email/address");
};

/**
 * 绑定邮箱
 */
export const bindEmail = (params: any) => {
  return http.post("/spb/email/address", params);
};

/**
 * 发送邮箱验证码
 */
export const sendBindEmailCode = (email: string) => {
  return http.post(`/spb/email/address/${email}`);
};

/**
 * 发送邮箱验证码
 */
export const unBindEmail = (email: string) => {
  return http.delete(`/spb/email/address/${email}`);
};
/**
 * 短信收取
 */
export const sendMessage = (message: any) => {
  return http.post(`/spb/bill/message`, message);
};

/**
 * 微信卡包
 */
export const uploadFromWxCard = (params: any) => {
  return http.post(`/spb/bill/invoice`, params);
};

/**
 * 上传单据
 */
export const uploadFile = (params: any) => {
  return http.post(`/spb/bill`, params);
};

/**
 * 获取单据列表
 */
export const getBillList = (params: any, config: any) => {
  return http.get(`/spb/bill`, { params, ...config });
};

/**
 * 获取单据信息
 */
export const getBillInfo = (id: any) => {
  return http.get(`/spb/bill/${id}`);
};

/**
 * 编辑单据
 */
export const editBill = (id: any, params: any) => {
  return http.put(`/spb/bill/${id}`, params);
};

/**
 * 删除单据
 */
export const deleteBill = (id: any) => {
  return http.delete(`/spb/bill/${id}`);
};

/**
 * 修改单据类型
 */
export const changeReceiptType = (params: any) => {
  return http.post(`/spb/bill/receipt`, params);
};

/**
 * 文件预览
 */
export const filePreview = (id: any) => {
  return http.get(`/spb/bill/preview/${id}`);
};

/**
 * 获取用户绑定财务软件
 */
export const getAccountSets = (config: any) => {
  return http.get(`/spb/user/account-set`, config);
};

/**
 * 提交财务
 */
export const submitFinance = (params: any) => {
  return http.put(`/spb/submission`, params);
};

/**
 * 添加意见反馈
 */
export const addFeedback = (params: any) => {
  return http.post("/spb/feedback", params);
};

/**
 * 意见反馈图片上传
 */
export const uploadFeedbackImage = (params: any) => {
  return http.post("/spb/feedback/attachment", params);
};

/**
 * 意见反馈图片删除
 */
export const deleteFeedbackImage = (params: any) => {
  return http.delete("/spb/feedback/attachment", { params });
};
