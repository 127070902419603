<template>
  <div class="common-title">
    <div class="left"></div>
    <span>{{title}}</span>
    <div class="subtitle">
      {{subtitle}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-title',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    }
  }
}
</script>

<style lang="less" scoped>
.common-title {
  padding: 12px 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  border-radius: 4px 4px 0 0;
  .left {
    width: 3px;
    height: 12px;
    margin-right: 8px;
    background: linear-gradient(180deg, #5FE688 0%, #3FCB7E 100%);
    border-radius: 3px;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
  }
}
</style>