<template>
  <div class="home-page" style="height: calc(100vh - 50px)">
    <div class="ad-img">
      <img src="@img/ad.png" alt="" @click="toShare" />
    </div>
    <div class="content">
      <CommonTitle class="lh-22" title="收票据" />
      <div class="card receive">
        <van-row>
          <van-col span="6">
            <CommonIcon
              url="mail.png"
              text="邮箱收取"
              @click.native="toEmail"
            />
          </van-col>
          <van-col span="6">
            <CommonIcon
              url="message.png"
              text="短信收取"
              @click.native="toMessage"
            />
          </van-col>
          <van-col span="6">
            <CommonIcon
              url="wechat.png"
              text="聊天导入"
              @click.native="toChat"
            />
          </van-col>
          <van-col span="6">
            <CommonIcon
              url="card.png"
              text="微信卡包"
              @click.native="toCardPackage"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="6">
            <div style="display: flex; justify-content: center">
              <CommonIcon
                url="camera.png"
                text="拍照上传"
                @click.native="beforeImgUpload"
              />
              <van-uploader
                v-show="false"
                ref="uploadImg"
                :before-read="beforeRead"
                :after-read="afterRead"
                :max-size="5 * 1024 * 1024"
                @oversize="onOversize"
                capture="camera"
              >
              </van-uploader>
            </div>
          </van-col>
          <van-col span="6">
            <div style="display: flex; justify-content: center">
              <CommonIcon
                url="upload.png"
                text="手工上传"
                @click.native="beforeFileUpload"
              />
              <van-uploader
                v-show="false"
                ref="uploadFile"
                :after-read="afterRead"
                :max-size="5 * 1024 * 1024"
                @oversize="onOversize"
                accept="image/*"
              >
              </van-uploader>
            </div>
          </van-col>
        </van-row>
      </div>
      <CommonTitle class="lh-22" title="交票据" />
      <div class="card sent">
        <van-row>
          <van-col span="6">
            <CommonIcon
              url="finance.png"
              text="提交财务"
              @click.native="submitFinance"
            />
          </van-col>
          <!-- 暂时隐藏 -->
          <!-- <van-col span="6">
            <CommonIcon url="share.png" text="分享" @click.native="onShare" />
          </van-col> -->
          <!-- <van-col span="6">
            <CommonIcon url="export.png" text="导出" @click.native="onExport" />
          </van-col> -->
        </van-row>
      </div>
    </div>

    <LayoutFooter />
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle.vue";
import CommonIcon from "@components/CommonIcon.vue";
import LayoutFooter from "@components/LayoutFooter.vue";
import {
  getBillList,
  uploadFile,
  getAccountSets,
  uploadFromWxCard,
} from "../../http/api";
import moment from "moment";
export default {
  name: "home",

  components: {
    CommonTitle,
    CommonIcon,
    LayoutFooter,
  },

  mounted() {
    if (this.$route.query.fileJson) {
      let json = this.$route.query.fileJson;
      json = json.replace(/\s/g, "+");
      this.uploadFromWxCard(JSON.parse(json));
    }
  },

  methods: {
    toShare() {
      let url = "/pages/share/index";
      this.$wechatMiniProgramNavigateTo({ url });
    },
    toEmail() {
      this.$checkLoginState(() => {
        this.$router.push({ name: "email" });
      });
    },
    toMessage() {
      this.$checkLoginState(() => {
        this.$router.push({ name: "message" });
      });
    },

    toChat() {
      this.$checkLoginState(() => {
        let url =
          "/pages/options/index?optionType=chatting&urlToken=" +
          localStorage.getItem("token");
        this.$hrefTo(url);
      });
    },

    toCardPackage() {
      this.$checkLoginState(() => {
        let url =
          "/pages/options/index?optionType=invoice&urlToken=" +
          localStorage.getItem("token");
        this.$hrefTo(url);
      });
    },

    beforeImgUpload() {
      this.$checkLoginState(() => {
        this.$refs.uploadImg.chooseFile();
      });
    },

    beforeFileUpload() {
      this.$checkLoginState(() => {
        this.$refs.uploadFile.chooseFile();
      });
    },

    onOversize(file) {
      this.$toast("文件大小不能超过 5M");
    },

    beforeRead(file) {
      let currentDate = moment(new Date()).format("YYYYMMDDHHmmss");
      let extension = file.name.split(".").slice(-1).join();
      return new Promise((resolve, reject) => {
        let newFile = new File([file], currentDate + "." + extension);
        resolve(newFile);
      });
    },

    afterRead(file) {
      this.$toast.loading({
        message: "正在上传...",
        forbidClick: true,
        duration: 0,
      });
      // 此时可以自行将文件上传至服务器
      let params = new FormData();
      params.append("bill", file.file);
      uploadFile(params).then((res) => {
        if (!res.statusCode) {
          this.$toast.error(res.message);
          return;
        }
        this.$toast.clear();
        this.$toast("上传成功");
      });
    },

    uploadFromWxCard(files) {
      this.$toast.loading({
        message: "正在上传...",
        forbidClick: true,
        duration: 0,
      });
      uploadFromWxCard({ invoices: files }).then((res) => {
        if (!res.statusCode) {
          this.$toast.clear();
          this.$toast.error(res.message);
          this.$router.replace({ name: "home" });
          return;
        }
        this.$toast.clear();
        this.$toast.success("上传成功");
        this.$router.replace({ name: "home" });
      });
    },

    submitFinance() {
      this.$checkLoginState(() => {
        this.$toast.loading({
          forbidClick: true,
          duration: 0,
        });
        getAccountSets().then((res) => {
          if (!res.statusCode) {
            this.$toast.clear();
            this.$toast(res.message);
            return;
          }
          if (res.data.length == 0) {
            this.$toast.clear();
            this.$dialog
              .confirm({
                className: "tip-model",
                message: "您还未绑定财务软件",
                theme: "round-button",
                confirmButtonText: "去绑定",
                confirmButtonColor: "#33C759",
                cancelButtonColor: "#ffffff",
              })
              .then(() => {
                let url = "/pages/share/index";
                this.$wechatMiniProgramNavigateTo({ url });
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.onCheck(
              () => {
                this.$router.push({ name: "finance" });
              },
              () => {
                this.$toast("暂无可提交的票据");
              }
            );
          }
        });
      });
    },
    onShare() {
      this.$checkLoginState(() => {
        this.onCheck(
          () => {
            this.$router.push({ name: "share" });
          },
          () => {
            this.$toast("暂无可分享的票据");
          }
        );
      });
    },
    onExport() {
      this.$checkLoginState(() => {
        this.onCheck(
          () => {
            this.$router.push({ name: "export" });
          },
          () => {
            this.$toast("暂无可导出的票据");
          }
        );
      });
    },
    onCheck(onSuccess, onFail) {
      getBillList({ pageSize: 10, currentPage: 1 }).then((res) => {
        this.$toast.clear();
        if (!res.statusCode) {
          this.$toast(res.message);
          return;
        }
        if (res.data.length == 0) {
          onFail();
          // this.$toast("暂无可提交的票据");
        } else {
          onSuccess();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ad-img {
  padding: 12px 15px;
  background: linear-gradient(#fff 50%, transparent 50%);
  img {
    display: block;
    width: 100%;
    height: 120px;
  }
}
.content {
  padding: 0 16px 50px;
  .common-title {
    margin-bottom: 12px;
    padding: 0;
    background-color: transparent;
    font-size: 16px;
  }
  .card {
    margin-bottom: 20px;
    padding: 20px 8px;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
    .van-row + .van-row {
      margin-top: 16px;
    }
    &.receive {
      background-image: url("~@img/bg-receive.png");
      background-size: 282px 37px;
      background-position: 50% 100%;
      background-repeat: no-repeat;
    }
    &.sent {
      background-image: url("~@img/bg-sent.png");
      background-size: 161px 36px;
      background-position: 50% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
<style lang="less">
.tip-model {
  .van-dialog__message {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
  }
  .van-dialog__footer {
    padding: 8px 28px 16px !important;
  }
  .van-button {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    + .van-button {
      margin-left: 24px;
    }
  }
  .van-dialog__cancel {
    color: #666666 !important;
    border: 1px solid #dddddd !important;
  }
}
</style>
