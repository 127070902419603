import axios from "axios";
import { Toast } from "vant";


const http = axios.create({
  baseURL:
    process.env.NODE_ENV == "development" ? undefined : process.env.VUE_APP_URL,
  timeout: 5 * 60 * 1000,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

//错误处理函数,
// function errorHandle({ data, status }) {}

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    if (config.url == "/v2/api/UserInfo") {
      config.baseURL = process.env.VUE_APP_AUTH_URL;
    }
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data.statusCode == false) {
      Toast.fail(data.message);
      return new Promise(() => {
        // do nothing.
      }); // a
    }
    return data;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          //如果登录失效则清理用户信息
          localStorage.clear();
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误，未找到该资源";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Toast.fail("服务器响应超时，请刷新当前页");
      }
      if (error.message.includes('cancel request')) {
        return new Promise(() => {
          // do nothing.
        });
      }
      error.message = "连接服务器失败";
    }
    Toast.fail(error.message);
    return new Promise(() => {
      // do nothing.
    });
  }
);

export default http;
