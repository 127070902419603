/**
 * 日期过滤器
 * 格式：{{date|timeFormat('yyyy-mm-dd')}}
 */
const dateFilter = (date: Date, pattern: string): string => {
  const o: any = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(pattern)) {
    pattern = pattern.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  for (const k in o) {
    if (isValidKey(k, o)) {
      if (new RegExp("(" + k + ")").test(pattern)) {
        pattern = pattern.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
  }

  return pattern;
};
interface date {
  "M+": number;
  "d+": number;
  "h+": number;
  "m+": number;
  "s+": number;
  "q+": number;
  S: number;
}
export function isValidKey(
  key: string | number | symbol,
  object: date
): key is keyof typeof object {
  return key in object;
}

export default {
  dateFilter,
};
